<template>
  <div>
    <b-table small responsive hover class="mt-3" :items="userList" primary-key="id">
      <template #head(_id)="data">
        <span style="display: none">{{ $t("ID") }}</span>
      </template>
      <template #cell(_id)="data">
        <!-- <span>{{ data.item._id }}</span> -->
      </template>
      <template #head(for)="data">
        <span>{{ $t("User") }}</span>
      </template>
      <template #head(status)="data">
        <span>{{ $t("Status") }}</span>
      </template>
      <template #cell(for)="data">
        <b-link :to="{ name: 'user-perms', params: { id: data.item.for._id } }">
          <b-button variant="primary">{{ data.item.for.name }}</b-button>
        </b-link>
      </template>
      <template #head(type)="data">
        <span>{{ $t("Type") }}</span>
      </template>
      <template #cell(type)="data">
        <span>{{ data.item.type }}</span>
      </template>
      <template #cell(status)="data">
        <b-button class="btn-icon m-1" variant="success">
          <feather-icon icon="CheckCircleIcon"></feather-icon>
        </b-button>
        <b-button variant="danger" class="btn-icon m-1">
          <feather-icon icon="XCircleIcon"></feather-icon>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BFormCheckbox, BTable, BButton, BLink, BModal } from "bootstrap-vue";
import axios from "@axios";
const {
  axiosRoutes: { approval: path },
} = require("/G_CONFIG");
export default {
  components: {
    BTable,
    BFormCheckbox,
    BButton,
    BLink,
    BModal,
  },
  data() {
    return {
      userList: null,
      permissionsData: [
        {
          module: "Admin",
          read: true,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: "Staff",
          read: false,
          write: true,
          create: false,
          delete: false,
        },
        {
          module: "Author",
          read: true,
          write: false,
          create: true,
          delete: false,
        },
        {
          module: "Contributor",
          read: false,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: "User",
          read: false,
          write: false,
          create: false,
          delete: true,
        },
      ],
    };
  },
  async created() {
    /* console.log(this.permissionsData); */
    await axios.get(path.getAll).then((response) => {
      this.userList = response.data;
    });
    // console.log(this.userList);
  },
};
</script>
<style scoped></style>
